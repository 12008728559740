<!--
 * @Description: 
 * @Version: 2.0
 * @Autor: cjw
 * @Date: 2024-11-05 16:47:33
 * @LastEditors: cjw
 * @LastEditTime: 2024-11-13 20:38:44
-->
<template>
  <div class='airheader'>
    <div class="city"><img src="../../../../assets/cockpit/dz.png" alt="" /> {{ this.cityName }}</div>
    <p>密闭场所空气监管平台</p>
    <div class="date"><img src="../../../../assets/cockpit/sj.png" alt="" />{{this.$moment(time).format('YYYY-MM-DD HH:mm')}}</div>
    <div class="user">
      <!-- <img src="../../../../assets/cockpit/avator.png" alt="" />
      <div>{{ userInfo.userName }}</div> -->
      <div class="back"><img width="90" src="../../../../assets/cockpit/back.png" alt=""> <span class="backText" @click="back">返回平台</span></div>
    </div>
  </div>
</template>

<script>

// import { getUser } from '@/api'
import { mapGetters} from 'vuex'
export default {
  data() {
    return {
      cityName: '浙江省 杭州市',
      date: '2021-07-01 10:59',
      userName: '王伟',
      time: this.$moment(),
      interval:null,
      userInfo:this.$store.state.user.headersInfo,
    };
  },
  created() {
  },
  computed: {
     ...mapGetters(['name','city'])
  },
  mounted() {

  },
  methods: {
    init(val) {
      this.cityName = val[0].regionName
    },
    back(){
      window.open('https://airvenue.terabits.cn/venue-management/index')
    }

  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
};
</script>

<style scoped lang="scss">
.airheader {
  
  height: 107px;
  width: 100%;
  background: url("../../../../assets/cockpit/header.png") no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  position: relative;

  p {
    width: 529px;
    margin: 34px auto 28px;
    font-size: 42px;
    font-weight: 400;
    text-align: center;
    color: #e8effb;
    font-weight: 600;
    letter-spacing: 2px;
    // background: linear-gradient(0deg, #12d1ff 0%, #ffffff 100%);
    background: linear-gradient(0deg, #B2CCFC 0%, #FFFFFF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  div {
    font-weight: bold;
    font-size: 16px;
    color: #00FFEA;
  }

  .city {
    position: absolute;
    top: 47px;
    left: 22%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 16px;
    width: 180px;
    text-align: center;
  }

  .date {
    position: absolute;
    top: 47px;
    right: 22%;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
  }
  .user{
    position: absolute;
    right: 22px;
    top: 2px;
    display: flex;
    align-items: center;
    font-size: 14px ;
    gap: 11px;;
    div{
      color: #fff;
    }
    .back{
      width: 90px;
      position: relative;
      margin-left: 10px;
     .backText{
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 14px;
      transform: translate(-50%,-50%);
      white-space: nowrap;
     }
     // background: url("../../../../assets/cockpit/back.png") no-repeat;
    }
  }
}
</style>
