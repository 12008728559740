<!--
 * @Description: 
 * @Version: 2.0
 * @Autor: cjw
 * @Date: 2024-11-05 16:47:33
 * @LastEditors: cjw
 * @LastEditTime: 2024-11-13 14:38:50
-->
<template>
  <div class='cockpit-left-top'>
      <div v-for="(el, index) in dataList" :key="index" class="content">
            <div class="imgBox">
           
              <!-- <img :src="require(el.url)" alt=""> -->
              <!-- <img src="../../../../assets/cockpit/kq.png" alt=""> -->
              <img class="center" :src="require(`../../../../assets/cockpit/tybg.png`)" alt="">
              <img class="center" :src="require(`../../../../assets/cockpit/${el.url}.png`)" alt="">
            </div>
            <div class="detail">
              <div class="num">{{ el.value ||0}}</div>
              <div class="name">{{ el.name }}</div>
            </div>
      </div>
  </div>
</template>

<script>
import { getBaseInfo } from '@/api/cockpit.js'
import { mapMutations } from 'vuex'
export default {
  data() {
    return {
        dataList:[
          {
            url:'xq',
            value:'9',
            name:'县区',
          },
          {
            url:'cs',
            value:'123',
            name:'场所',
          },
          {
            url:'sbs',
            value:'3%',
            name:'设备数',
          },
          {
            url:'yxts',
            value:'123',
            name:'运行天数',
          }

        ],
    }
  },
  created() {

  },

  mounted() {
    this.init()
    // this.drawSmallEcharts()
  },
  methods: {
    ...mapMutations(['SET_NAME']),
    init(){
      getBaseInfo().then(res=>{
        console.log(res)
        this.dataList[0].value = res.data.referDistrictsCount
        this.dataList[1].value = res.data.venueTotalCount;//场所
        this.dataList[2].value = res.data.deviceTotalCount;
        this.SET_NAME(res.data.currentRegion)
      // this.SET_NAME('杭州市')
       // this.dataList[3].value = res.data.operationDays;//运行天数
        
        this.dataList[3].value = this.$moment().diff(this.$moment('2024-11-01'), 'days');//运行天数
        this.$store.commit('SET_HEADERS',  res.data)
      })
    }
  }
};
</script>

<style scoped lang="scss">
.cockpit-left-top{
  display: flex;
  height: 250px;
 
  .content{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .imgBox{
      position: relative;
      width: 101px;
      height: 73px;
      padding: 10px; /* 添加内边距以确保内容与边框之间有间距 */
      .center{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .detail{
      margin-top: 25px;
      margin-left: 5px;
      width: 91px;
      height: 79px;
      padding: 10px 2px;
      background: url("../../../../assets/cockpit/vuenesbg.png") no-repeat;
      .num{
        font-weight: bold;
        font-size: 30px;
        text-align: center;
        color: #08FCFF;
      }
      .name{
        font-weight: 400;
        font-size: 18px;
        text-align: center;
        color: #FFFFFF;
      }
    }
  }

}
</style>
