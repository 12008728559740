<!--
 * @Description: 
 * @Version: 2.0
 * @Autor: cjw
 * @Date: 2024-11-05 17:51:09
 * @LastEditors: cjw
 * @LastEditTime: 2024-11-14 14:22:06
-->
<template>
  <div class='air-left-top aircommon'>
    <p class="title">
      空气质量优良率详情
    </p>
    <div class="content-title">
      <el-row >
        <el-col :span="6">县区名称</el-col>
        <el-col style="text-align: center;" :span="6">上月优良率</el-col>
        <el-col style="text-align: center;" :span="6">本月优良率</el-col>
        <el-col style="text-align: center;" :span="6">综合优良率</el-col>
      </el-row>
    </div>
    <div class="swiper swiper_cockpit_leftCenter">
      <div class="swiper-wrapper">
      <el-row v-for="(el, index) in dataList" class="swiper-slide row" :key="index" >
         <el-col :span="6">{{ el.regionName }}</el-col>
        <el-col style="text-align: center; color: #CACACA; ;" :span="6">{{  el.lastMonthStatistic}}%</el-col>
        <el-col style="text-align: center;" :span="6">{{ el.curMonthStatistic }}%</el-col>
        <el-col style="text-align: center;color: #15EAFF;" :span="6">{{ el.statistic }}%</el-col>
      </el-row>
    </div>
    </div>
  </div>
</template>

<script>
import { getAirQualityLevel } from '@/api/cockpit'
import Swiper, { Autoplay } from 'swiper';
import 'swiper/swiper.scss';
Swiper.use([Autoplay])
export default {
  data() {
    return {
      chart: null,
      timeInterval: null,
      dataIndex: 0,
      dataList: [
        // { value: 0, name: 'CO', itemStyle: { color: '#12B796' } },
        // { value: 0, name: 'CO2', itemStyle: { color: '#1DADFF' } },
        // { value: 0, name: '甲醛', itemStyle: { color: '#24DCF7' } },
        // { value: 0, name: '温度', itemStyle: { color: '#A87EF3' } },
        // { value: 0, name: '湿度', itemStyle: { color: '#1C8AC3' } }
      ],

    }
  },
  created() {

  },
  mounted() {
     this.init()
    // this.drawSmallEcharts()
  },
  methods: {
    init() {
      getAirQualityLevel().then(res => {
        console.log('数据', res)
        this.dataList = res.data
        this.$nextTick(()=>{
          this.initSwiper()
        })
      //  this.initSwiper()
        // let data = res.data.alarmTypeList
        //const data=res.data
        // let arr=[
        //   data.humidityAlarmCount,
        //   data.temperatureAlarmCount,
        //   data.pm10AlarmCount,
        //   data.pm25AlarmCount,
        //   data.co2AlarmCount,
        // ]
      })
    },
    initSwiper () {
        if (this.dataList && this.dataList.length > 5) {
          new Swiper('.swiper_cockpit_leftCenter', {
            autoplay: {
              delay: 0,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            direction: 'vertical',
            slidesPerView: 'auto',
            loop: true,
            speed: 1500
          })
        }
        
    }
  }
};
</script>

<style scoped lang="scss">
.air-left-top {
  width: 100%;
  height: 328px;
  background: url("../../../../assets/air/left_top.png") no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
    .content-title {
      font-size: 16px;
      font-weight: bold;
      color: #15eaff;
      padding: 24px 24px 0 25px;
      
    }
    .content-list {
      font-size: 14px;
      font-weight: bold;
      color: #fff;
     
      padding: 24px 24px 0 25px;
      .row{
       padding-bottom:21px;
      }
    }
    .swiper_cockpit_leftCenter {
      padding: 0 30px;
      overflow: hidden;
      height: 200px;
      margin-top: 20px;
      .swiper-wrapper{
        display: flex;
        flex-direction: column;
      }
      .swiper-slide {
        display: flex;
        margin-bottom: 25px;
        height: 15px;
        .el-col {
          float: none;
          font-size: 14px;
          color: #ffffff;
        }
        // .el-col-4 {
        //   color: #15eaff;
        // }
      }
    }
    
  
}
</style>