<!--
 * @Description: 
 * @Version: 2.0
 * @Autor: cjw
 * @Date: 2024-11-05 16:47:33
 * @LastEditors: cjw
 * @LastEditTime: 2024-11-13 08:56:57
-->
<template>
  <div class="air">
    <air-header ref="header"/>
    <!-- <div id='fullscreen'>
    </div> -->
    <div class="left">
      <left-top />
      <left-center />
      <left-bottom />
    </div>
    <center-top ref="centermap" />
    <center-bottom />
    <div class="right">
      <right-top />
      <right-center />
      <right-bottom />
    </div>
  </div>
</template>

<script>
import { getAreaDeviceNum } from '@/api/cockpit'
import AirHeader from './components/header'
import LeftTop from './components/leftTop'
import LeftCenter from './components/leftCenter'
import LeftBottom from './components/leftBottom'
import CenterTop from './components/centerTop'
import CenterBottom from './components/centerBottom'
import RightTop from './components/rightTop'
import RightCenter from './components/rightCenter'
import RightBottom from './components/rightBottom'
export default {
  components: { AirHeader, LeftTop, LeftCenter, LeftBottom, CenterTop, CenterBottom, RightTop, RightCenter, RightBottom },
  data() {
    return {
      map: null,
      markList: [],
      cityName: ''
    };
  },
  created() {
    this.getData()
  },
  mounted() {
    //this.init()
    // window.onload = function () {
    //   let h = document.getElementsByClassName("left")[0].offsetHeight
    //   const height = h + 67
    //   document.getElementsByClassName("air")[0].style.height = height + 'px';
    // }
    // this.loadMap()
  },
  methods: {
    getData() {
      getAreaDeviceNum().then(res => {
        this.$refs.centermap.initList(res.data)
        this.$refs.header.init(res.data)
      })
    }

  }
};
</script>

<style scoped lang="scss">
.air {
  background: #000001;
  padding-top: 1px;
  height: 1080px;
  position: relative;
  background: url("../../assets/city/bg.png") no-repeat 100% 100%;

  #fullscreen {
    width: 100vw;
    height: calc(100vh - 85px);
  }

  .left {
    width: 514px;
    position: absolute;
    z-index: 18;
    top: 107px;
    // bottom: 0;
    left: 34px;
  }

  .right {
    width: 514px;
    position: absolute;
    z-index: 18;
    top: 107px;
    right: 36px;
  }

  // .center {
  //   // width: 789px;
  //   margin: 41px 10px 0 24px;
  //   height: 100px;
  //   // position: absolute;
  //   // top: 108px;
  // }
}
</style>
