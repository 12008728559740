/*
 * @Description: 
 * @Version: 2.0
 * @Autor: cjw
 * @Date: 2024-11-11 10:23:38
 * @LastEditors: cjw
 * @LastEditTime: 2024-11-12 09:42:15
 */
import request from '../utils/request'
// import * as config from "../utils/config";datav/countStatistic
// import store from '../store'

const getBaseInfo = () => request.post(`/airVenue/cockpit/get/cockpitBaseData`)

//设备在线率
const getDeviceOnlineRate = () => request.post(`/airVenue/cockpit/get/deviceOnlineRate`)

//获取场所类型分布数据
const getPlaceType = () => request.post(`/airVenue/cockpit/get/venueTypeData`)

//获取告警详情分页

const getAlarmDetail = (params={pageNp:1,pageSize:5}) => request.post(`/airVenue/cockpit/get/alarmPushInfo`, params)
//获取告警分布数据
const getAlarmDistribution = () => request.post(`/airVenue/cockpit/get/alarmCommentData`)

//获取空气质量等级统计
const getAirQualityLevel = (params={pageNp:1,pageSize:5}) => request.get(`/airVenue/cockpit/getAirLevelMonthStatistic`,params)

//空气质量优良率
const getAirQualityGoodRate = () => request.get(`/airVenue/cockpit/getAirLevelChangeStatistic`)

//获取区域场馆设备数

const getAreaDeviceNum = (params={}) => request.post(`/airVenue/cockpit/get/regionCountData`,params)

export{
    getBaseInfo,
    getDeviceOnlineRate,
    getPlaceType,
    getAlarmDetail,
    getAlarmDistribution,
    getAirQualityLevel,
    getAirQualityGoodRate,
    getAreaDeviceNum
}