<template>
  <div class='air-right-top aircommon'>
    <div class="title">
      场所类型分布

    </div>
    <div id="roseChart"></div>
  </div>
</template>

<script>
import { getPlaceType } from '@/api/cockpit.js'


export default {
  data() {
    return {
      // chart: null,
      timeDataList: [],
      location: '',
      index: 0,
      timer: null
    };
  },
  created() {

  },
  mounted() {
    this.init()

  },
  methods: {
    init() {
      getPlaceType().then(res => {
        console.log('饼图数据', res)
        let data = [
          { value: res.data.superMarketCount, name: '超市' },
          { value: res.data.cinemaCount, name: '影院' },
          { value: res.data.hotelCount, name: '酒店' },
          { value: res.data.busStopCount, name: '汽车站' },
          { value: res.data.fitnessCenterCount, name: '健身中心' },
          { value: res.data.libraryCount, name: '图书馆' },
          { value: res.data.clubCenterCount, name: '会议场所' }
        ]
        this.drawEcharts(data)
      })
    },
    drawEcharts(data) {


      var chartDom = document.getElementById('roseChart');
      var myChart = this.$echarts.init(chartDom);
      var option;
      option = {

        legend: {
          top: 'middle',
          orient: '',
          right: 10,
          itemGap: 10,
          textStyle: { color: "#fff" },
        },
        color: ['#73D7E3', '#EDC159', '#4E87F7', '#EB7D97', '#C55636', '#BC50E5', '#97BE3B'],
        series: [
          {
            name: 'Nightingale Chart',
            type: 'pie',
            radius: [18, '80%',],
            center: ['40%', '50%'],
            roseType: 'area',
            // avoidLabelOverlap:true,
            label: {
              color: 'inherit',
              fontSize: 16,
              formatter: '{name|{b}}\n{time|{c}}',
              // minMargin: 100,
              // edgeDistance: 2,
              // alignTo: 'edge',
              // position: 'outer',
              //lineHeight: 15,
              rich: {
                time: {
                  fontSize: 14,
                  color: '#fff',
                  height: 20,
                  align: 'center'
                  //textAlign: 'center',
                }
              }
            },

            labelLayout: function (params) {
              const isLeft = params.labelRect.x < myChart.getWidth() / 2;
              const points = params.labelLinePoints;
              // Update the end point.
              points[2][0] = isLeft
                ? params.labelRect.x
                : params.labelRect.x + params.labelRect.width;
              return {
                labelLinePoints: points,
              };
            },
            data:data,


          },

        ]
      };

      option && myChart.setOption(option);
    },

  },
  destroyed() {

  }
};
</script>

<style scoped lang="scss">
.air-right-top {
  width: 100%;
  height: 328px;
  background: url("../../../../assets/air/right_top.png") no-repeat;
  background-size: 100% 100%;

  .title {
    display: flex;
    align-items: center;

    p {
      margin-left: 70px;
      height: 30px;
      line-height: 30px;

      span {
        background: rgba(0, 0, 0, 0.4);
        display: inline-block;
        border-radius: 2px;
        font-size: 16px;
        color: #15eaff;
        padding: 0 20px;
        cursor: pointer;
        font-weight: 500;
      }
    }
  }

  #roseChart {
    margin-top: 30px;
    width: 500px;
    height: 200px;
  }
}
</style>
