<template>
  <div class='air-center-bottom '>
    <p class="title">
      告警推送详情
    </p>
    <div class="alarmData_content">
      <el-row class="alarmData_title">
        <el-col :span="6">场所名称</el-col>
        <el-col :span="3">所属区域</el-col>
        <el-col :span="6">告警内容</el-col>
        <el-col :span="3">处理情况</el-col>
        <el-col :span="6">处理时间</el-col>
      </el-row>
      <div class="swiper swiper_cockpit_centerBtoom" >
        <div class="swiper-wrapper">
          <el-row v-for="(el, index) in alarmDataList" class="swiper-slide row" :key="index">
            <el-col :span="6" class="overflow">{{ el.venueName }}</el-col>
            <el-col :span="3">{{ el.regionName }}</el-col>
            <el-col :span="6" class="overflow" style="color: #CACACA;">{{ el.content }}</el-col>
            <el-col :span="3" :style="{color:el.handleFlag==1?'#EF4864':'#1AFA29'}">{{ el.handleFlag==1?'未处理':'已处理' }}</el-col>
            <el-col :span="6">{{ el.handleTimeStr ||'-'}}</el-col>
          </el-row>
          <!-- <el-row class="swiper-slide">
            <el-col :span="6" class="overflow">浙江金翅膀体育培训有限公司</el-col>
            <el-col :span="3">金华市</el-col>
            <el-col :span="6" class="overflow" style="color: #CACACA;">甲醛一小时平均值超过多少的杀杀杀</el-col>
            <el-col :span="3" style="color:#1AFA29">已处理</el-col>
            <el-col :span="6">2024-08-31 14;13;09</el-col>
          </el-row> -->
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {getAlarmDetail}from '@/api/cockpit.js'
import Swiper, { Autoplay } from 'swiper';
import 'swiper/swiper.scss';
Swiper.use([Autoplay])
export default {
  data () {
    return {
      alarmDataList: [
      ],
     
    };
  },
  created () {

  },
  mounted () {
    this.init()
    this.refresh()
    //this.initSwiper()
  },
  methods: {
    init () {
      getAlarmDetail().then(res => {
        res.data.list.map(el => {
          switch (el.alarmType) {
            case 'co':
              el.alarmName = 'CO'
              break;
            case 'co2':
              el.alarmName = 'CO2'
              break;
            case 'formaldehyde':
              el.alarmName = '甲醛'
              break;
            case 'temperature':
              el.alarmName = '温度'
              break;
            case 'humidity':
              el.alarmName = '湿度'
              break;
          }
        })
        this.alarmDataList = res.data.list
        this.$nextTick(()=>{
          this.initSwiper()
        })
      })
    },
    initSwiper () {
        if (this.alarmDataList && this.alarmDataList.length > 5) {
          new Swiper('.swiper_cockpit_centerBtoom', {
            autoplay: {
              delay: 0,
              stopOnLastSlide: false,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            },
            direction: 'vertical',
            slidesPerView: 'auto',
            loop: true,
            speed: 1500
          })
       }
      
    },
    refresh() {
      let timer = setInterval(() => {
        this.init()
      }, 600000);
      this.$once('hook:beforeDestroy', () => {
        clearInterval(timer);
        timer = null;
      })
    }
    
  },
 

};
</script>

<style scoped lang="scss">
.air-center-bottom {
  width: 789px;
  height: 336px;
  overflow: hidden;
  background: url("../../../../assets/air/center.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  // top: 107px;
  bottom: 30px;
  left: 50%;
  margin-left: -394px;
  text-align: center;
  .title {
    font-size: 18px;
    color: #ffffff;
    margin-top: 16px;
    // margin: 0 auto;
  }
  .alarmData_content {
    padding: 24px 24px 0 25px;
    background: rgba(4, 32, 49, 0.4);
    .alarmData_title {
      font-size: 16px;
      font-weight: bold;
      color: #15eaff;
      display: flex;
      .el-col {
        float: none;
      }
    }
    .swiper_cockpit_centerBtoom {
      overflow: hidden;
      height: 200px;
      margin-top: 20px;
      .swiper-wrapper{
        display: flex;
        flex-direction: column;
      }
      .swiper-slide {
        display: flex;
        margin-bottom: 25px;
        height: 15px;
        .el-col {
          float: none;
          font-size: 14px;
          color: #ffffff;
        }
        // .el-col-4 {
        //   color: #15eaff;
        // }
      }
    }
  }
  .overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    /* word-break: break-all; */
    white-space: nowrap;
  }
}
</style>
