<template>
  <div class='air-left-bottom aircommon'>
    <div class="title">
      空气质量优良率变化
    </div>
    <div id='linechart'>折线图</div>

  </div>
</template>

<script>
//import { getRealTimeRank } from '@/api'

import { getAirQualityGoodRate } from '@/api/cockpit'

export default {
  data() {
    return {
      lineData: ['60', '65', '70', '61', '80', '50'],
      xData: ['5月', '6月', '7月', '8月', '9月', '10月'],
    }
  },
  created() {

  },
  mounted() {
     this.init()
    //this.drawLine()
  },
  methods: {
    init() {
      getAirQualityGoodRate().then(res => {
        console.log('折线数据', res)
        //this.lineData = res.data
        let xdata=[];
        let ydata=[];
        res.data.forEach(el => {
          xdata.push(el.curDate)
          ydata.push(el.statistic)
        })
        
        this.drawLine(xdata,ydata)
      })
    },
    drawLine(xdata,ydata) {
      const chart = this.$echarts.init(document.getElementById('linechart'))
      const option = {
        tooltip: {
          show: false,
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: '#57617B'
            }
          }
        },
        // legend: {
        //   icon: 'rect',
        //   itemWidth: 14,
        //   itemHeight: 5,
        //   itemGap: 13,
        //   data: ['南宁-曼芭', '桂林-曼芭', '南宁-甲米'],
        //   right: '4%',
        //   textStyle: {
        //     fontSize: 12,
        //     color: '#F1F1F3'
        //   }
        // },
        grid: {
          left: '5%',
          right: '10%',
          bottom: '3%',
          top: '20%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          axisLine: {
            lineStyle: {
              color: '#E1FFFE'
            }
          },
          //name: '(日期)',
          boundaryGap: false,
          offset: 5,
          data: xdata
        }],
        yAxis: [{
          type: 'value',
          name: '单位（%）',
          axisLine: {
            show: true,
            lineStyle: {
              color: '#E1FFFE'
            }
          },
          axisLabel: {
            margin: 10,
            // textStyle: {
            fontSize: 14
            // }
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: '#57617B'
            }
          }
        }],
        series: [
          {
            type: 'line',
            smooth: true,
            lineStyle: {
              color: '#00BCFF',
              width: 3
            },
            symbol: 'none',
            areaStyle: {
              // normal: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(76,246,243,0.2)'
              }, {
                offset: 0.8,
                color: 'rgba(76,246,243,0)'
              }], false),
              shadowColor: 'rgba(0, 0, 0, 0.1)',
              shadowBlur: 10
              // }
            },
            itemStyle: {
              // normal: {
              color: 'rgb(137,189,27,0)'
              // }
            },
            data: ydata
          },

        ]
      };

      option && chart.setOption(option)
      window.addEventListener("resize", () => {
        chart.resize();
      })
    }

  }
};
</script>

<style scoped lang="scss">
.air-left-bottom {
  margin-top: 28px;
  width: 100%;
  height: 328px;
  background: url("../../../../assets/air/left_bottom.png") no-repeat;
  background-size: 100% 100%;

  .title {
    display: flex;
    align-items: center;

    // justify-content: space-between;
    p {
      display: flex;
      margin-left: 36px;
      height: 30px;
      line-height: 30px;

      // padding: 2px 0;
      span {
        display: inline-block;
        border: 1px solid rgba(202, 202, 202, 0.5);
        border-radius: 2px;
        font-size: 16px;
        color: #cacaca;
        padding: 0 20px;
        cursor: pointer;
      }

      .active {
        font-weight: 500;
        color: #15eaff;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 2px;
        border: none;
      }
    }
  }

  #linechart {
    margin-top: 30px;
    width: 500px;
    height: 200px;
  }
}
</style>
