<template>
  <div class='air_right_bottom aircommon'>
    <p class="title">
      告警内容分类
    </p>
    <div id="radioChart">

    </div>
  </div>
</template>
<script>
import { getAlarmDistribution } from '@/api/cockpit'
export default {
  data() {
    return {
      rankDataList: [
        // { id: 1, name: '大西洋银泰百货' },
        // { id: 2, name: '大西洋银泰百货' },
        // { id: 3, name: '大西洋银泰百货' },
        // { id: 4, name: '大西洋银泰百货' }
      ],
      timeDataList: [],
      timer: null
    };
  },
  created() {

  },
  mounted() {
   
    this.init()
    this.refresh()
    // this.drawEcharts()
  },
  methods: {
    init() {
      getAlarmDistribution().then(res => {
        console.log('雷达数据', res)
        // let data = res.data.alarmTypeList
        const data=res.data
        let arr=[
          data.humidityAlarmCount,
          data.temperatureAlarmCount,
          data.pm10AlarmCount,
          data.pm25AlarmCount,
          data.co2AlarmCount,
          data.coAlarmCount,
          data.tvocAlarmCount,
          data.ch2oAlarmCount,
        ]
        this.drawEcharts(arr)
      })
    },
    drawEcharts(arr) {
      let chart = this.$echarts.init(document.getElementById('radioChart'))
      // const singleMsg = this.timeDataList[index]
      let arr1 = arr
      let i = -1;
      const option = {
        radar: {
          center: ['50%', '51%'],
          indicator: [

            { name: '湿度',  },
            { name: '温度',  },
            { name: 'PM10', },
            { name: 'PM2.5', },
            { name: 'CO2', },
            { name: 'CO', },
            { name: 'TVOC', },
            { name: '甲醛',  },
          ],
          radius: '70%',
          // axisName: {
          //   color: "rgba(202, 202, 202, 1)",
          //   fontSize: 14,
          //   fontWeight: 600
          // },
          // 边框样式
          splitLine: {
            lineStyle: {
              // 使用深浅的间隔色
              color: 'rgba(0, 255, 255, 0.2)',
              width: 2
            }
          },
          // 对角线样式
          axisLine: {
            lineStyle: {
              color: "rgba(0, 255, 255, 0.2)",
              type: "dashed",
              width: 2
            }
          },
          // 区域样式
          splitArea: {
            areaStyle: {
              color: ['rgba(250,250,250,0)', 'rgba(200,200,200,0)']
            }
          },
          name: {
            rich: {
              a: {
                fontWeight: 400,
                fontSize: '14px',
                color: ' #CACACA'
              },
              b: {
                fontSize: '20px',
                color: '#32DAFF',
                fontWeight: 'bold',
                align: 'center',

                padding: 2,
                borderRadius: 4
              }
            },
            formatter: (a) => {
              i++;
              return `{a|${a}}{b|${arr1[i]}}`
            }
          }
        },
        series: [
          {
            // name: 'Budget vs spending',
            type: 'radar',
            // 
            symbolSize: 6,
            itemStyle: {
              color: "rgba(253, 179, 87, 1)"
            },
            lineStyle: {
              color: "rgba(253, 179, 87, 1)",
              width: 0.5
            },
            areaStyle: {
              color: "rgba(31, 34, 24, 0.8)"
            },
            data: [
              {
                // value: [singleMsg.formaldehyde, singleMsg.humidity, singleMsg.temperature, singleMsg.co2, singleMsg.co]
                // name: 'Allocated Budget'
                value: arr1
              }
            ]
          }
        ]
      }
      option && chart.setOption(option)
    },
    refresh() {
      let timer = setInterval(() => {
        this.init()
      }, 600000);
      this.$once('hook:beforeDestroy', () => {
        clearInterval(timer);
        timer = null;
      })
    }
  },

};
</script>

<style scoped lang="scss">
.air_right_bottom {
  width: 100%;
  height: 328px;
  margin-top: 32px;
  background: url("../../../../assets/air/right_bottom.png") no-repeat;
  background-size: 100% 100%;
}

#radioChart {
  margin-top: 30px;
  width: 500px;
  height: 230px;
}
</style>
