<!--
 * @Description: 
 * @Version: 2.0
 * @Autor: cjw
 * @Date: 2024-11-05 16:47:33
 * @LastEditors: cjw
 * @LastEditTime: 2024-11-14 15:59:29
-->
<template>
  <div class='cockpit-left-top'>
    <div v-for="(el, index) in dataList" :key="index" class="content">
      <div class="imgBox">

        <!-- <img :src="require(el.url)" alt=""> -->
        <!-- <img src="../../../../assets/cockpit/kq.png" alt=""> -->
        <img :src="require(`../../../../assets/cockpit/${el.url}.png`)" alt="">
      </div>
      <div class="detail">
        <div class="num">{{ el.value }}</div>
        <div class="name">{{ el.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDeviceOnlineRate } from '@/api/cockpit.js'
export default {
  data() {
    return {
      dataList: [
        {
          url: 'kq',
          value: '80%',
          name: '空气传感器在线率',
        },
        {
          url: 'fl',
          value: '80%',
          name: '风量传感器在线率',
        },
        {
          url: 'yw',
          value: '80%',
          name: '烟雾传感器在线率',
        },
        {
          url: 'hw',
          value: '80%',
          name: '红外摄像头在线率',
        }

      ],

    }
  },
  created() {

  },
  mounted() {

    this.init()
    this.refresh()
    // this.drawSmallEcharts()
  },
  methods: {
    init() {
      getDeviceOnlineRate().then(res => {
        console.log(res)
        this.dataList[0].value = res.data.airDeviceOnlineRate
        this.dataList[1].value = res.data.windDeviceOnlineRate;//场所
        this.dataList[2].value = res.data.smogDeviceOnlineRate;
        this.dataList[3].value = res.data.cameraDeviceOnlineRate;//运行天数

      })
    },
    refresh() {
      let timer = setInterval(() => {
        this.init()
      }, 600000);
      this.$once('hook:beforeDestroy', () => {
        clearInterval(timer);
        timer = null;
      })
    }

  },

};
</script>

<style scoped lang="scss">
.cockpit-left-top {
  display: flex;
  height: 250px;
  flex-wrap: wrap;

  .content {
    width: 50%;
    display: flex;

    .imgBox {
      // border: 2px dashed #808080;
      width: 101px;
      height: 73px;
      //padding: 10px; /* 添加内边距以确保内容与边框之间有间距 */
    }

    .detail {
      margin-top: 10px;
      margin-left: 5px;

      .num {
        font-weight: bold;
        font-size: 30px;
        color: #08FCFF;
        margin-bottom: 5px;
      }

      .name {
        margin-top: 6px;
        font-weight: 400;
        font-size: 17px;
        color: #FFFFFF;
        position: relative;
        left: -10px;
      }
    }
  }

}
</style>
